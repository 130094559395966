import { atom } from "jotai";

export const atomDialogVisible = atom(false);

export const atomFilterPrice = atom("any");

export const atomFilterDate = atom<
	| {
			dateLb: number;
			dateUb: number;
	  }
	| undefined
>(undefined);
