/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useTheme } from "@mui/material";
import { memo } from "react";
import Footer from "../footer";
import HeaderLogged from "../headers/HeaderLogged";
import HeaderPublic from "../headers/HeaderPublic";

export interface ILayoutFrontend {
	children: any;
	token?: string;
	noFooter?: boolean;
	[x: string]: any;
}

const iubendaImplementation = `
		<script type="text/javascript">
		var _iub = _iub || [];
		_iub.csConfiguration = {"askConsentAtCookiePolicyUpdate":true,"consentOnContinuedBrowsing":false,"invalidateConsentWithoutLog":true,"perPurposeConsent":true,"siteId":698716,"whitelabel":false,"cookiePolicyId":8004150,"lang":"it", "banner":{ "position": "bottom", "acceptButtonCaptionColor":"white","acceptButtonColor":"#0073CE","acceptButtonDisplay":true,"backgroundColor":"#000001","closeButtonRejects":true,"customizeButtonCaptionColor":"white","customizeButtonColor":"#212121","customizeButtonDisplay":true,"explicitWithdrawal":true,"listPurposes":true,"rejectButtonCaptionColor":"white","rejectButtonColor":"#0073CE","rejectButtonDisplay":true,"textColor":"white" }};
		</script>
		<script type="text/javascript" src="//cdn.iubenda.com/cs/iubenda_cs.js" charset="UTF-8" async crossorigin=""></script>`;

const LayoutFrontend = memo(({ children, token, noFooter, ...otherProps }: ILayoutFrontend) => {
	const theme = useTheme();

	return (
		<div>
			<div css={{ position: "sticky", top: 0, zIndex: theme.zIndex.appBar + 1 }} {...otherProps}>
				{token ? <HeaderLogged /> : <HeaderPublic />}
			</div>

			{process.env.NODE_ENV === "production" && <div dangerouslySetInnerHTML={{ __html: iubendaImplementation }} />}

			<div
				css={css`
					position: relative;
					min-height: 62vh;
					background-color: white;
				`}
			>
				{children}
			</div>
			{!noFooter && <Footer />}
		</div>
	);
});

export default LayoutFrontend;
