/** @jsxImportSource @emotion/react */
import { Grid, GridProps, GridSize } from "@mui/material";
import { useMemo } from "react";

import isArray from "lodash/isArray";

interface StackProps extends GridProps {
	columns?: 1 | 2 | 3 | 4 | 6;
	direction?: "row" | "column" | "row-reverse" | "column-reverse";
}
const Stack: React.FC<StackProps> = ({ children, columns, ...props }) => {
	const localChildren = useMemo(() => {
		if (isArray(children)) return children;
		return [children];
	}, [children]);

	const alignmentProps = {
		[getPropertyToAlignFromDirection(props.direction)]: "center",
	};

	return (
		<Grid container {...alignmentProps} {...props}>
			{localChildren.map((x, i) => (
				<Grid
					item
					xs={(columns ? 12 / columns : "auto") as GridSize}
					key={i}
					css={{ display: "flex", [getPropertyToAlignFromDirection(props.direction)]: "center" }}
				>
					{x}
				</Grid>
			))}
		</Grid>
	);
};

const getPropertyToAlignFromDirection = (direction?: string) => {
	if (direction?.includes("row")) return "alignItems";
	if (direction?.includes("column")) return "justifyContent";
	return "alignItems";
};

export default Stack;
