/** @jsxImportSource @emotion/react */
import { Box, BoxProps, useTheme } from "@mui/material";

interface HoverFeedbackProps extends BoxProps {
	disabled?: boolean;
	radius?: number;
	hoverColor?: string;
	activeColor?: string;
}
const HoverFeedback: React.FC<HoverFeedbackProps> = ({
	padding,
	children,
	onClick,
	disabled,
	hoverColor,
	activeColor,
	radius,
	...others
}) => {
	const theme = useTheme();
	return (
		<Box
			css={
				disabled
					? {
							cursor: "not-allowed",
							borderRadius: radius ?? 12,
							userSelect: "none",
							opacity: 0.6,
					  }
					: {
							cursor: "pointer",
							borderRadius: radius ?? 12,
							userSelect: "none",
							opacity: 1,
							":hover": {
								backgroundColor: hoverColor ?? theme.palette.primary.a12,
							},
							":active": {
								backgroundColor: activeColor ?? theme.palette.primary.a38,
							},
					  }
			}
			padding={padding || 1}
			onClick={disabled ? () => {} : onClick}
			{...others}
		>
			{children}
		</Box>
	);
};
export default HoverFeedback;
